import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import styled from 'styled-components';
import { BlogsIcon } from './BlogsIcon.js';
import { EditIcon } from './EditIcon.js';
import { HomeIcon } from './HomeIcon.js';
import { LocationIcon } from './LocationIcon.js';
import { JobsIcon } from './JobsIcon.js';
import { ApplicationIcon } from './ApplicationIcon.js';
import { useMediaQuery } from 'react-responsive';
import { DeviceSize } from '../responsive';
import auth from '../../auth';
import LocalStorageService from '../../LocalStorageService';
import Logo from "../../assets/images/newLogo.png";

const Container = styled.div`
  height: 100vh;
  width: 100px;
  background-color: white;
  box-shadow: 4px 0px 8px 4px rgba(202, 202, 202, 0.1);
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: center;
  :last-child: {
    padding: 50px;
  }
  //position: fixed;

  .sp-logo {
    width: 80px;
  }

  @media only screen and (max-width: ${DeviceSize.newMedium}px) {
    .sp-logo {
      width: 60px;
    }
  }

  ${(props) =>
    props.hover === 'applyHover' &&
    `
        width: 140px
  
  `}
`;
const IconWrapper = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  // height: 40%;
  height: 23rem;
  //justify-content: space-evenly;
`;

const Icon = styled.div`
  padding: 7px;
  padding-top: 20px;
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &:before {
    position: absolute;
    height: 44px;
    margin-top: -7px;
    width: 6px;
    background-color: #e83741;
    left: 2px;
    border-radius: 5px;
  }
  &:hover {
    background: rgba(241, 225, 225, 0.47);
    border-radius: 5px;

    &:before {
      content: '';
    }
  }
  ${(props) =>
    props.active &&
    `
         background: rgba(241, 225, 225, 0.47);
    border-radius: 5px;

    &:before {
      content: "";
    }
  
  `}
`;

export function Sidebar() {
  function getInitialState() {
    return LocalStorageService.get('SelectedOption') || 'home';
  }
  const [active, setactive] = useState(getInitialState());
  const [hover, setHover] = useState('');
  const history = useHistory();
  const location = useLocation();
  const [url, setUrl] = useState('');
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.newMedium });

  function setSelectedOption(option) {
    LocalStorageService.set({ SelectedOption: option });
    setactive(option);
  }

  useEffect(() => {
    //get the pathananem url
    var paths = location.pathname.split('/');

    //beacuse there is subSidebar in company and org dashboard
    let subProfile = ['Profil', 'Logo', 'Galerie', 'Konto'];
    let subBlogs = ['Bewerber', 'Jobs'];

    if (auth.isCompany()) {
      subBlogs.push('Blogeinträge');
    }

    let sidebarItem = paths[2] || 'home';

    let subSidebarItem = subProfile.filter(
      (x) => x.toLowerCase() === paths[2]
    )[0];

    //check if pathname is one on the subsidebar items
    if (subSidebarItem !== undefined) {
      sidebarItem = 'profil';
    } else {
      subSidebarItem = subBlogs.filter((x) => paths[2] === x.toLowerCase())[0];
      if (subSidebarItem == 'jobs') {
        sidebarItem = 'jobs';
      } else if (subSidebarItem == 'Bewerber') {
        sidebarItem = 'bewerbungen';
      } else {
        subSidebarItem = paths[2] || 'home';
      }
    }
    if (sidebarItem.includes('-')) {
      const value = sidebarItem.split('-')[0];
      if (value === 'account') sidebarItem = 'konto';
    }

    if (sidebarItem === 'profileinstellungen') sidebarItem = 'profil';
    if (sidebarItem === 'jobs') 
    
    auth.isApplicant() ? sidebarItem = 'jobs' :
    sidebarItem = 'bewerbungen';

    setSelectedOption(sidebarItem);
    localStorage.setItem(`SubSidebar`, subSidebarItem.toLowerCase());
  }, [location.pathname]);

  useEffect(() => {
    switch (auth.getType()) {
      case 'Applicant':
        setUrl('/bewerber-dashboard');
        break;
      case 'Organisation':
        setUrl('/organisations-dashboard');
        break;
      case 'Company':
        setUrl('/arbeitgeber-dashboard');
        break;
    }
  }, []);

  return (
    <Container id="sidebar" className={isMobile ? 'mobile' : ''} hover={hover}>
      <img
        className="sp-logo"
        src={Logo}
        alt="logo"
        onClick={() =>
          history.push(
            !auth.isAuthenticated()
              ? '/'
              : auth.isCompany()
              ? '/arbeitgeber-dashboard'
              : auth.isOrganization()
              ? '/organisations-dashboard'
              : '/bewerber-dashboard'
          )
        }
        style={{ cursor: 'pointer' }}
      />
      <hr style={{ color: '#f4f4f4', height: 1, width: '100%' }} />
      <IconWrapper>
        {' '}
        <Icon
          onMouseEnter={() => setHover('applyHover')}
          onMouseLeave={() => {
            setHover('');
          }}
          active={active === 'home'}
          onClick={() => {
            setSelectedOption('home');
            history.push(url);
          }}
        >
          <HomeIcon
            stroke={active === 'home' ? '#E83741' : null}
            hoverForParagraph={hover === 'applyHover' ? true : false}
          />
        </Icon>{' '}
        <Icon
          onMouseEnter={() => setHover('applyHover')}
          onMouseLeave={() => {
            setHover('');
          }}
          active={active === 'profil'}
          onClick={() => {
            history.push(
              auth.isApplicant()
                ? '/bewerber-dashboard/profileinstellungen'
                : `${url}/profil`
            );

            setSelectedOption('profil');
          }}
        >
          <EditIcon
            stroke={active === 'profil' ? '#E83741' : null}
            hoverForParagraph={hover === 'applyHover' ? true : false}
          />
        </Icon>
        {!auth.isApplicant() && (
          <Icon
            onMouseEnter={() => setHover('applyHover')}
            onMouseLeave={() => {
              setHover('');
            }}
            active={active === 'standorte'}
            onClick={() => {
              history.push(`${url}/standorte`);

              setSelectedOption('standorte');
            }}
          >
            <LocationIcon
              stroke={active === 'standorte' ? '#E83741' : null}
              hoverForParagraph={hover === 'applyHover' ? true : false}
            />
          </Icon>
        )}{' '}
        <Icon
          onMouseEnter={() => setHover('applyHover')}
          onMouseLeave={() => {
            setHover('');
          }}
          active={active === 'bewerbungen'}
          onClick={() => {
            setSelectedOption('bewerbungen');
            let path;

            if (auth.isApplicant()) {
              path = '/bewerber-dashboard/bewerber';
            } else if (auth.isOrganization()) {
              path = `${url}/jobs`;
            } else {
              path = `${url}/jobs`;
            }
            history.push(path);
          }}
        >
          <ApplicationIcon
            stroke={active === 'bewerbungen' ? '#E83741' : null}
            hoverForParagraph={hover === 'applyHover' ? true : false}
          />
        </Icon>
        {/* {auth.isApplicant() ? (
          <Icon
            active={active === 'konto'}
            onClick={() => {
              history.push('/bewerber-dashboard/account-einstellungen');
              setSelectedOption('konto');
            }}
          >
            <GearsIcon stroke={active === 'konto' ? '#E83741' : ''} />
          </Icon>
        ) : null} */}
        {auth.isApplicant() ? (
          <Icon
            onMouseEnter={() => setHover('applyHover')}
            onMouseLeave={() => {
              setHover('');
            }}
            active={active === 'jobs'}
            onClick={() => {
              history.push('/bewerber-dashboard/jobs');
              setSelectedOption('jobs');
            }}
          >
            <JobsIcon
              stroke={active === 'jobs' ? '#E83741' : null}
              hoverForParagraph={hover === 'applyHover' ? true : false}
            />
          </Icon>
        ) : null}
        {auth.isApplicant() ? (
          <Icon
            onMouseEnter={() => setHover('applyHover')}
            onMouseLeave={() => {
              setHover('');
            }}
            active={active === 'blogs'}
            onClick={() => {
              history.push('/bewerber-dashboard/blogs');
              setSelectedOption('blogs');
            }}
          >
            <BlogsIcon
              stroke={active === 'blogs' ? '#E83741' : null}
              hoverForParagraph={hover === 'applyHover' ? true : false}
            />
          </Icon>
        ) : null}
      </IconWrapper>
      {/* 
      <Icon
        onClick={() => auth.logout((_) => history.push('/'))}
        style={{ textAlign: 'center', marginTop: 'auto' }}
      >
        <LogoutIcon />
      </Icon> */}
    </Container>
  );
}

export default Sidebar;
