import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { FacebookShareButton, LinkedinShareButton, FacebookIcon, LinkedinIcon } from 'react-share';
import parse from 'html-react-parser';
import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip, Fade } from '@mui/material';
import { AssignmentTurnedIn, Delete, Edit, Settings, Storage } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Select } from '@mui/material';

import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import ComponentWrapper from '../components/ComponentWrapper/ComponentWrapper';
import SubmitButton from '../components/Buttons/submitButton';
import CustomModal from '../components/modal/Modal';
import { Flex } from '../components/Common/Input';
import Snackbar from '../components/snackbar/snackbar';
import styles from '../components/Job/Job.module.scss';
import CircularLoader from '../components/Loader/CircularLoader';
import SocialShare from '../components/SocialShare/SocialShare';
import { DeviceSize } from '../components/responsive';
import InputField from '../components/InputField/InputField';

import GetData from '../api/GetData';
import GetDataPublic from '../api/GetDataPublic';
import PostData from '../api/PostData';
import PostPublicData from '../api/PostPublicData';

import stepsDone from '../assets/images/stepsDone.png';
import applicant from '../assets/images/aplicant.png';
import { ReactComponent as BusinessIcon } from '../assets/images/icon-business.svg';
import { ReactComponent as PaperclipIcon } from '../assets/images/icon-paperclip.svg';
import { ReactComponent as ClockIcon } from '../assets/images/clock.svg';
import { ReactComponent as CenterIcon } from '../assets/images/icon-business-center.svg';
import { ReactComponent as LocationIcon } from '../assets/images/icon-job-location.svg';
import { ReactComponent as PersonIcon } from '../assets/images/icon-person.svg';
import { ReactComponent as EmploymentIcon } from '../assets/images/EmploymentIcon.svg';
import InfoButton from '../assets/images/info_button.svg';

import auth from '../auth';
import "../assets/scss/jobPage.scss";

const ExpressbewerbungTooltip = `In kürzester Zeit kannst Du dich bei deinem Traumjob bewerben. 
Gebe dazu lediglich deinen Vor-, Nachnamen, deine E-Mail-Adresse und deine Telefonnummer an. 
Der Arbeitgeber könnte dich schon bald kontaktieren, wenn er an dir interessiert ist.
 
Bitte beachte: Der Arbeitgeber kann nicht auf sonstige Daten von dir zurückgreifen. 
Mögliche besondere Stärken, Qualifikationen oder Fähigkeiten finden daher keine Beachtung. 
Willst Du diese zur Geltung bringen, so nutze bitte die detaillierte spiderjob-Bewerbung.`

const SpiderjobBewerbungTooltip = `Du möchtest einen passgenauen Job ohne Suchaufwand von der spiderjob-Software vorgeschlagen bekommen? 
Dann nutze die detaillierte spiderjob-Bewerbung. 
Nimm dir ein paar Minuten Zeit und fülle dein Profil gewissenhaft aus. 
Je genauere Angaben du machst, umso besser funktioniert das Matching. 
Im Anschluss bekommst Du konkrete Jobvorschläge mit genauer Prozentangabe.`

const JobPage = () => {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();

  const [user, setuser] = useState(null);
  const [jobUserPhoto, setjobUserPhoto] = useState(null);
  const [MatchingModalOpen, setMatchingModalOpen] = useState(
    location.state && location.state.showModal && auth.isCompany()
  );
  const [applyModalOpen, setapplyModalOpen] = useState(false);
  const [appliedToJob, setappliedToJob] = useState(false);
  const [snackbar, setsnackbar] = useState(false);
  const [anchorEl, setanchorEl] = useState(null);
  const [loading, setloading] = useState(false);
  const [job, setJob] = useState(null);
  const [isOwner, setisOwner] = useState(false);
  const [contactPerson, setcontactPerson] = useState(null);
  const [selectedEmployments, setSelectedEmployment] = useState([]);
  const [branches, setBranches] = useState([]);
  const [officeEmployments, setOfficeEmployments] = useState([]);

  const isLarge = useMediaQuery({ maxWidth: DeviceSize.newLarge });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const [employmentType, setEmploymentType] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [allEmployments, setAllEmployments] = useState('');
  const [singleEmployment, setSingleEmployment] = useState('');
  const [typeOfApply, setTypeOfApply] = useState(false);
  const [quickApply, setQuickApply] = useState(false);
  const [quickApplyValid, setQuickApplyValid] = useState(false);
  const [quickApplyData, setQuickApplyData] = useState({
    firstName: "",
    lastName: "",
    number: "",
    email: ""
  });

  const getJobApplication = async (jobid, userid) => {
    const response = await GetData(
      `JobApplicant?JobAdvertisementId=${jobid}&ApplicantId=${userid}&IncludeJobAdv=true`
    );
    if (response.length > 0) {
      setappliedToJob(true);
    }
  };

  //get all data needed for page
  useEffect(() => {
    (async () => {
      setloading(true);
      let user;
      if (auth.isAuthenticated()) {
        user = await GetDataPublic(`User/${auth.getId()}`);
        setuser(user);
        setQuickApplyData({
          firstName: user.FirstName,
          lastName: user.LastName,
          number: user.Phone,
          email: user.Email
        })
      }

      //new requests
      const res = await GetDataPublic(
        `jobadvertisement/${params.id.split('-').pop()}`
      );
      user && (await getJobApplication(res.JobAdvertisementId, user.Id));

      setjobUserPhoto(res.Company.ProfileImageUrl);
      setcontactPerson(res.Contact);
      setEmploymentType(res.Employments.length - 1);
      setSingleEmployment(res.Employments[0].EmploymentType);
      setJob(res);
      let employ = '';
      res.Employments.map((employments, index) => {
        employ = employ + employments.EmploymentType;
        if (index != res.Employments.length - 1) employ += ', ';
      });
      setAllEmployments(employ);

      setBranches(
        [res.Office, res.Office2, res.Office3].filter(
          (b) => b !== null && b !== undefined
        )
      );
      setloading(false);
    })();
  }, []);

  useEffect(() => {
    if (job) {
      if ((job && job.ApplicationUserId) === (user && user.Id)) {
        setisOwner(true);
      }
    }
  }, [job, user]);

  const onSubmit = async () => {
    if (!user) {
      history.push('/login');
    } else {
      try {
        setloading(true);
        let jobApplicantData = {
          jobAdvertisementId: job.JobAdvertisementId,
          applicantId: user.Id,
          officeEmployments: officeEmployments
        };
  
        await PostData('JobApplicant', jobApplicantData);
        setappliedToJob(true);
        setloading(false);
        setapplyModalOpen(false);
        setsnackbar(true);
      } catch (error) {
        console.log('🚀 ~ error', error);
      }
    }
  };

  const onQuickApply = async () => {
    try {
      setloading(true);
      let userData = {
        name: quickApplyData.firstName,
        lastName: quickApplyData.lastName,
        phoneNumber: quickApplyData.number,
        email: quickApplyData.email,
        jobAdvertisementId: job.JobAdvertisementId,
        ...(user && {applicantId: user.Id}),
        officeEmployments: officeEmployments
      };
      
      await PostPublicData('api/quickapply', userData);
      setappliedToJob(true);
      setloading(false);
      setQuickApply(false);
      setsnackbar(true);
    } catch (error) {
      console.log('🚀 ~ error', error);
      setloading(false);
    }
  }

  const handleSpiderjobBewerbung = () => {
    setTypeOfApply(false);
    setapplyModalOpen(true)
  }

  const handleSchenllbewerbung = () => {
    setTypeOfApply(false);
    setQuickApply(true);
  }

  const handleInputChange = (event) => {
    const { target: { name, value } } = event
    setQuickApplyData({...quickApplyData, [name]: value })

  }
  
  useEffect(() => {
    setQuickApplyValid(quickApplyData.email && quickApplyData.firstName && quickApplyData.lastName && quickApplyData.number? true : false)
  }, [quickApplyData])

  return (
    <ComponentWrapper displayFlex flexColumn noPadding minHeight="130vh">
      <Snackbar
        open={snackbar}
        onClose={() => setsnackbar(false)}
        severity="success"
      >
        Erfolgreich beworben!
      </Snackbar>
      <Header />
      {loading ? (
        <>
          <ComponentWrapper displayFlex justifyCenter alignCenter grow>
            <CircularLoader />
          </ComponentWrapper>
        </>
      ) : (
        <>
          <div
            style={isMobile ? { overflow: 'hidden' } : {}}
            className={`job-preview ${styles.jobPreview}`}
          >
            <div className={styles.imageBlock}>
              <img src={job?.Photo?.Url} alt="jobPhoto" />
            </div>
            <div className={styles.backgroundMargin} />
            <div className={styles.companyInfo}>
              {!isMobile && (
                <SocialShare
                  style={
                    isLarge
                      ? { right: '30px', top: '-400px' }
                      : { right: '118px', top: '-290px' }
                  }
                />
              )}
              <img
                src={jobUserPhoto}
                alt="company image"
                className={styles.companyImage}
              />

              <h4 className={styles.jobTitle} style={{ textAlign: 'center' }}>
                {job?.Profession?.ProfesionName ?? job?.ProfessionText}
                {isOwner && (
                  <IconButton
                    className="ikonica"
                    onClick={(e) => {
                      e.stopPropagation();
                      setanchorEl(e.currentTarget);
                    }}
                    size="large"
                  >
                    {Boolean(anchorEl) ? (
                      <Settings color="secondary" />
                    ) : (
                      <Settings />
                    )}
                  </IconButton>
                )}
              </h4>
              <p className={styles.jobSubtitle}> (m | w | d)</p>
              <h4 className={styles.jobHeading}>
                <span className={styles.jobSpecification}>
                  {' '}
                  {job?.JobAdSpecification}
                </span>
              </h4>
              <div className={styles.companyContent}>
                <div className={styles.contentCard}>
                  <div className={styles.cardIcon}>
                    <BusinessIcon />
                  </div>
                  <img src={jobUserPhoto} />
                  <p className={styles.companyName}>
                    {job?.Company.CompanyName}
                  </p>
                  <p className={styles.companyLocation}>
                    {' '}
                    {job?.Company?.CompanyLocation?.FormattedAddress}
                  </p>
                  <button
                    onClick={() =>
                      history.push(
                        `/arbeitgeber/${encodeURIComponent(
                          job?.Company.CompanyName.replace('+', '')
                        )}-${job?.Company.Id}`
                      )
                    }
                  >
                    {job?.IsVolunteering
                      ? 'Zum Organisationsprofil'
                      : 'Zum Firmenprofil'}
                  </button>
                </div>

                <div
                  className={styles.contentCard}
               
                >
                  <div className={styles.cardIcon}>
                    <PaperclipIcon />
                  </div>
                  <div    style={clicked ? { overflowY: 'auto' } : {}} > 
                  <div className={styles.cardList}>
                    <ClockIcon />
                    <div>
                      <p className={styles.listTitle}>Beschäftigungsbeginn:</p>
                      <p className={styles.listName}>
                        {job?.StartingDate.Type}
                      </p>
                    </div>
                  </div>
                  <div className={styles.cardList}>
                    <LocationIcon />
                    <div>
                      <p className={styles.listTitle}>
                        Zweigstelle / Niederlassung:
                      </p>
                      <p className={styles.listName}>{`${
                        job?.Office.BranchType
                      } ${(job?.Office?.Location?.StreetNo && ',') || ''} ${
                        job?.Office?.Location?.CityName || ''
                      }`}</p>
                    </div>
                  </div>

                  <div className={styles.cardList}>
                    <CenterIcon />
                    <div>
                      <p className={styles.listTitle}>Branche:</p>
                      <p className={styles.listName}>
                        {job?.Branch.BranchType}
                      </p>
                    </div>
                  </div>
                  <div className={styles.cardList}>
                    <EmploymentIcon />
                    <div style={{ width: '220px' }}>
                      <p className={styles.listTitle}>Anstellungsart:</p>
                      <div style={{ display: 'flex' }}>
                        <p className={styles.listName}>
                          {!clicked ? singleEmployment : allEmployments}
                        </p>
                        {employmentType != 0 && !clicked && (
                          <p
                            className={styles.listName}
                            style={{
                              color: '#E83741',
                              fontWeight: '500',
                              marginLeft: '5px',
                              cursor: 'pointer'
                            }}
                            onClick={() => {
                              setClicked(true);
                              setSingleEmployment('');
                            }}
                          >
                            {'+' + employmentType}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  </div>
                </div>

                <div className={styles.contentCard}>
                  {contactPerson?.File?.Url ? (
                    <img
                      src={contactPerson?.File?.Url}
                      alt="company image"
                      className={styles.cardIcon}
                    />
                  ) : (
                    <div className={styles.cardIcon}>
                      <PersonIcon />
                    </div>
                  )}
                  <p className={styles.contact}>Ansprechpartner/in</p>
                  <p className={styles.contactName}>
                    {contactPerson?.Title} {contactPerson?.FirstName}{' '}
                    {contactPerson?.LastName}
                  </p>
                  <p className={styles.contactTitle}>
                    {contactPerson?.Position}
                  </p>
                  <p className={styles.contactPhone}> {contactPerson?.Phone}</p>
                  <p className={styles.contactPhone}> {contactPerson?.Email}</p>
                </div>
              </div>
            </div>

            <div className={styles.introduction}>
              {parse(job?.IntroductionText ?? '')}
            </div>
            <div className={styles.jobRequirements}>
              {job?.Tasks && (
                <div className={styles.requirementCard}>
                  <h4> {job?.ApplicantAddressing} Aufgaben:</h4>
                  <div>{parse(job?.Tasks)}</div>
                </div>
              )}

              {job?.Profil && (
                <div className={styles.requirementCard}>
                  <h4> {job?.ApplicantAddressing?.slice(0, -1)} Profil: </h4>
                  <div>{parse(job?.Profil)}</div>
                </div>
              )}

              {job?.Offer && (
                <div className={styles.requirementCard}>
                  <h4> Wir bieten:</h4>

                  <div>{parse(job?.Offer)}</div>
                </div>
              )}
            </div>

            <div className={styles.introduction}>
              {' '}
              {parse(job?.ClosingText ?? '')}{' '}
            </div>

            {!isOwner &&
              !auth.isCompany() &&
              !auth.isOrganization() &&
              job?.ShouldRedirectExternal == false && (
                <div className={`search-bar ${styles.searchBar}`}>
                  <div className={`search-fields ${styles.searchFields}`}>
                    <Select
                      multiple
                      required
                      className={`search-bar-input ${styles.input}`}
                      value={officeEmployments.map((o) => o.officeId)}
                      onChange={(e) => {
                        setOfficeEmployments(
                          e.target.value.map((val) => {
                            return {
                              officeId: val,
                              employmentIds: []
                            };
                          })
                        );
                      }}
                      displayEmpty
                      renderValue={(selected) =>
                        selected.length
                          ? selected
                              .map(
                                (s) =>
                                  branches?.find((e) => e.OfficeId === s)
                                    ?.BranchType
                              )
                              .join(', ')
                          : 'Standort/Niederlassung wählen'
                      }
                    >
                      {branches?.map((item, index) => (
                        <MenuItem value={item.OfficeId} key={item.OfficeId}>
                          <Checkbox
                            checked={officeEmployments?.some(
                              (s) => s.officeId === item.OfficeId
                            )}
                          />
                          <ListItemText primary={item.BranchType} />
                        </MenuItem>
                      ))}
                    </Select>

                    {officeEmployments.map((oe) => (
                      <div className={styles.selectedLocation}
                      key={oe.officeId}
                      >
                        <p>
                          {' '}
                          {
                            branches?.find((e) => e.OfficeId === oe.officeId)
                              ?.BranchType
                          }{' '}
                        </p>
                        <Select
                          multiple
                          required
                          className={`search-bar-input ${styles.input}`}
                          value={oe?.employmentIds}
                          onChange={(e) => {
                            setSelectedEmployment(e.target.value);
                            setOfficeEmployments((prevState) =>
                              prevState.map((el) =>
                                el.officeId === oe.officeId
                                  ? { ...el, employmentIds: e.target.value }
                                  : el
                              )
                            );
                          }}
                          input={<OutlinedInput />}
                          displayEmpty
                          renderValue={(selected) =>
                            selected.length
                              ? selected
                                  .map(
                                    (s) =>
                                      job?.Employments?.find(
                                        (e) => e.EmploymentId === s
                                      )?.EmploymentType
                                  )
                                  .join(', ')
                              : 'Anstellungsart wählen'
                          }
                        >
                          {job?.Employments?.map((item, index) => (
                            <MenuItem
                              value={item.EmploymentId}
                              key={item.EmploymentId}
                            >
                              <Checkbox
                                checked={oe?.employmentIds?.includes(
                                  item.EmploymentId
                                )}
                              />
                              <ListItemText primary={item.EmploymentType} />
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                    ))}

                    <SubmitButton
                      onClick={() => setTypeOfApply(true)}
                      disabled={
                        isOwner ||
                        auth.isCompany() ||
                        auth.isOrganization() ||
                        appliedToJob ||
                        !officeEmployments.length ||
                        !selectedEmployments.length
                      }
                      className={styles.submitBewerben}
                    >
                      {appliedToJob ? 'Bereits Beworben' : 'Jetzt bewerben'}
                    </SubmitButton>
                  </div>
                </div>
              )}

            {job?.ShouldRedirectExternal == true && (
              <SubmitButton
                onClick={() => {
                  const trenutniProtokol = window.location.protocol;

                  const novaDomena = job?.RedirectExternalUrl.includes('https')
                    ? job?.RedirectExternalUrl
                    : `${trenutniProtokol}//${job?.RedirectExternalUrl}`;

                  window.location.href = novaDomena;
                }}
                className={styles.submitBewerben}
              >
                Jetzt bewerben
              </SubmitButton>
            )}
          </div>

          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setanchorEl(null)}
            onClick={() => setanchorEl(null)}
            transformOrigin={{
              horizontal: 'right',
              vertical: 'right'
            }}
          >
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: auth.isCompany()
                    ? '/arbeitgeber-dashboard/jobs'
                    : '/organisations-dashboard/jobs',
                  state: { job: job }
                });
              }}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              Bearbeiten
            </MenuItem>
            {auth.isCompany() && (
              <MenuItem
                onClick={() => {
                  localStorage.setItem('JobAd', JSON.stringify(job));
                  history.push('/match');
                }}
              >
                <ListItemIcon>
                  <AssignmentTurnedIn />
                </ListItemIcon>
                Matching hinzufügen
              </MenuItem>
            )}

            <MenuItem
              onClick={() => {
                history.push({
                  pathname: auth.isCompany()
                    ? '/arbeitgeber-dashboard/bewerber'
                    : '/organisations-dashboard/bewerber',
                  state: { searchJob: job }
                });
              }}
            >
              <ListItemIcon>
                <Storage />
              </ListItemIcon>
              Bewerbungen
            </MenuItem>
            <MenuItem
              onClick={() => {
                history.push({
                  pathname: auth.isCompany()
                    ? '/arbeitgeber-dashboard/jobs'
                    : '/organisations-dashboard/jobs',
                  state: {
                    job: job,
                    delete: true
                  }
                });
              }}
              style={{ color: '#e83741' }}
            >
              <ListItemIcon>
                <Delete color="primary" />
              </ListItemIcon>
              Löschen
            </MenuItem>
          </Menu>

          {/* modal to show and edit matching && apply to job */}
          <CustomModal
            open={Boolean(applyModalOpen || MatchingModalOpen)}
            onClose={() => {
              MatchingModalOpen
                ? setMatchingModalOpen(false)
                : setapplyModalOpen(false);
            }}
            withRadius
            small
            smallHeight
          >
            <Flex column CenterCenter style={{ height: '100%' }}>
              {(MatchingModalOpen || user) && (
                <h3
                  style={{
                    color: '#e83741',
                    fontWeight: 600,
                    marginBottom: 40
                  }}
                >
                  GESCHAFFT !
                </h3>
              )}

              <p
                style={{
                  marginBottom: 40,
                  fontSize: 20,
                  maxWidth: '90%',
                  textAlign: 'center'
                }}
              >
                {MatchingModalOpen
                  ? 'Möchten Sie jetzt das Matching hinzufügen?'
                  : user
                  ? 'Willst du dich wirklich bewerben?'
                  : 'Du musst eingeloggt sein, um dich für den Job zu bewerben!'}
              </p>
              <img
                // src={user ? stepsDone : applicant}
                src={MatchingModalOpen || user ? stepsDone : applicant}
                alt="stepsDone"
                style={{ marginBottom: 30 }}
              ></img>
              <ComponentWrapper>
                <SubmitButton
                  small
                  disabled={appliedToJob || loading}
                  buttonClicked={loading}
                  onClick={() => {
                    if (MatchingModalOpen) {
                      localStorage.setItem('JobAd', JSON.stringify(job));
                      history.push('/match');
                    } else {
                      onSubmit();
                    }
                  }}
                >
                  {MatchingModalOpen ? 'Ja' : user ? 'Ja' : 'Login'}
                </SubmitButton>
              </ComponentWrapper>
              <SubmitButton
                style={{ top: '0px' }}
                small
                inversed={true}
                onClick={() => {
                  if (!MatchingModalOpen && !user) {
                    history.push('/profil-anlegen');
                  } else if (MatchingModalOpen) {
                    setMatchingModalOpen(false);
                  } else {
                    setapplyModalOpen(false);
                  }
                }}
              >
                {MatchingModalOpen || user ? 'Nein' : 'Profil anlegen'}
              </SubmitButton>
            </Flex>
          </CustomModal>

            {/* Type of employment */}
          <CustomModal
            maxWidth= "500px"
            height="300px"
            open={typeOfApply}
            onClose={() => setTypeOfApply(false)}
            className="job-page__type-of-apply"
          >
            <Flex column CenterCenter style={{ height: '100%' }}>
              <p>Fast geschafft...</p>
              <p>Wir freuen uns über deine Bewerbung!</p>
              <p>Wie willst du dich bewerben?</p>

            <div 
              className="type-of-apply__button-wrapper"
            >
              <SubmitButton
              className="type-of-apply__button"
              onClick={handleSchenllbewerbung}
              >
                   Expressbewerbung
              </SubmitButton>
              <Tooltip
              title={ExpressbewerbungTooltip}
              placement="right"
              enterTouchDelay="1400ms"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                style={{
                  padding: 8,
                  top: '0px',
                  height: '22px',
                  width: '22px',
                  marginBottom: '2px'
                }}
                onClick={(e) => {}}
                size="large"
              >
                <img src={InfoButton} />
              </IconButton>
            </Tooltip>
            </div>


            <div  className="type-of-apply__button-wrapper">
              <SubmitButton
              className="type-of-apply__button"
              onClick={handleSpiderjobBewerbung}
              >
                 spiderjob-Bewerbung
              </SubmitButton>
              <Tooltip
              title={SpiderjobBewerbungTooltip}
              placement="right"
              enterTouchDelay="1400ms"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <IconButton
                style={{
                  padding: 8,
                  top: '0px',
                  height: '22px',
                  width: '22px',
                  marginBottom: '2px'
                }}
                onClick={(e) => {}}
                size="large"
              >
                <img src={InfoButton} />
              </IconButton>
            </Tooltip>

          </div>

            </Flex>
          </CustomModal>




          <CustomModal
        open={quickApply}
        onClose={() => setQuickApply(false)}
        withRadius
        maxWidth="600px"
        height="650px"
        className="quick-apply"
      >
        <Flex column padding h90>
          <p className="quick-apply__title">Expressbewerbung!</p>

          <InputField
          label="Vorname*" 
          value ={quickApplyData.firstName}
          name="firstName"
          handleChange= {handleInputChange}
          />

          <InputField
          label="Nachname*" 
          value ={quickApplyData.lastName}
          name="lastName"
          handleChange= {handleInputChange}
          />

          <InputField
          label="Telefon/Mobil Nummer*" 
          value ={quickApplyData.number}
          name="number"
          handleChange= {handleInputChange}
          />

          <InputField
          label="Email*" 
          value ={quickApplyData.email}
          name="email"
          type="email"
          handleChange= {handleInputChange}
          />

<p className="quick-apply__statement">Mit dem Klick auf ‘Jetzt bewerben’ stimme ich den Rechtlichen Informationen der spiderjob GmbH zu und erlaube die Kontaktaufnahme.</p>


          <SubmitButton
            className="quick-apply__button"
            onClick={onQuickApply}
            disabled={!quickApplyValid}
          >
            Jetzt bewerben
          </SubmitButton>

        </Flex>
      </CustomModal>
        </>
      )}
      <span
        style={{
          marginTop: '30px',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          opacity: '0.8',
          fontSize: '16px',
          color: '#1f1d0d'
        }}
      >
        Teile via
      </span>
      <div
        style={{
          flexDirection: 'row',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          marginTop: '5px',
          margin: '0 auto'
        }}
      >
        <FacebookShareButton
          url={window.location.href}
          style={{ marginRight: '5px' }}
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <LinkedinShareButton url={window.location.href}>
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

      <Footer />
    </ComponentWrapper>
  );
};

export default JobPage;
